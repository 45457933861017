import React from 'react'
import Layout from '../components/layout'
import ServicesData from '../content/services_data.json'
import Service from '../components/Service'
import external from '../assets/images/external.jpg'

class Vehicles extends React.Component {

  render () {
    return (
      <Layout>
          <Service 
            name={ "Enlaces externos" } 
            description={ "Los enlaces externos son todas las conexiones con las que dispone Trámites Dorado para realizar de manera idónea los procesos ante las secretarías del tránsito en el Cauca. Entre nuestros enlaces se encuentran: Centros de reconocimiento de conductores, escuelas de automovilismo, centros de diagnóstico automotor, oficinas de abogados, entre otros." } 
            img={ external }
            service_data={ ServicesData.external_links } />
      </Layout>
    )
  }
}

export default Vehicles