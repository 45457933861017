import React from 'react'

class Item extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        isDetailsVisible: false,
    };
    this.toogleDetails = this.toogleDetails.bind(this);
  }
  toogleDetails() {
      this.setState(prevState => ({
          isDetailsVisible: !prevState.isDetailsVisible && this.props.description !== undefined
      }));
  }
  render() {
  return (
      <div className="box">
          <h2 className="clickable text-center"><a onClick={this.toogleDetails}>{this.props.name} </a></h2>
          {
              this.state.isDetailsVisible ? 
              <div>
                <p>{this.props.description}</p>
                {
                  this.props.requirements !== undefined ?
                  <div>
                    <h3>Requisitos</h3>
                    <ol>
                      {this.props.requirements.map((data, index) => {
                          return <li key={`service_${index}`}>{data}</li>
                      })}
                    </ol>
                    <a href="https://api.whatsapp.com/send?phone=573103904606&text=Hola%2C%20acabo%20de%20ver%20tu%20sitio%20web%20y%20me%20interesan%20sus%20servicios" target="_blank" rel="noopener noreferrer" className="button  fit icon fa-whatsapp">Contactar</a>
                  </div>
                  : null
                }
              </div> : null
          }
      </div>
      )
  }
}

class Service extends React.Component {
  render() {
    return (
      <div>
        <div id="main" className="alt">
          <section id="one">
            <div className="inner">
              <header className="major">
                <h1>{ this.props.name }</h1>
              </header>
              <span className="image main"><img className="lazyload" data-src={ this.props.img } alt="Service image" /></span>
              <p>{ this.props.description }</p>
              <div className="grid-wrapper">
                {
                  this.props.service_data.map((data, index) => {
                    return (
                      <div className="col-4" key={index} id={data.name}>
                        <Item name={data.name} description={data.description} requirements={data.requirements}/>
                      </div>  
                    )
                  })
                }
              </div>
            </div>
          </section>
        </div>
      </div>
    )
  }
}

export default Service